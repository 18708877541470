.aboutSecond {
	background: #0d0c1d
}

.aboutThird {
	background: #111021; /* fallback for old browsers */
	
}

.aboutFourth {
    background: #0d0c1d
}

.aboutFifth {
    background: #111021;
}

.aboutSixth {
    background: #111021;
}

.aboutSeventh {
    background: #00284C;
}
