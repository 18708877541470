/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 27, 2020 */



@font-face {
    font-family: 'carnas-bolduploaded_file';
    src: url('carnas_bold-webfont.woff2') format('woff2'),
         url('carnas_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'carnas-regularuploaded_file';
    src: url('carnas_regular-webfont.woff2') format('woff2'),
         url('carnas_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'carnas-lightuploaded_file';
    src: url('carnas_light-webfont.woff2') format('woff2'),
         url('carnas_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}