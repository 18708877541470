$break-small: 621px;
$break-large: 1200px;
.intro-main {
	height: 100vh;
	margin: 0;
	display: grid;
	place-items: center;

	.main-row-container {
		border: 2px solid transperent;
		background-color: white;
		border-image-slice: 1;
		border-radius: 20px;
		height: 700px;
		width: 1200px;
		padding-left: 0;
		@media screen and (max-width: $break-small) {
			height: 100vh;
			width: 100vw;
			overflow-y: scroll;
			border-radius: 0px;
		}
		.left-container {
			@media screen and (max-width: $break-small) {
				display: none;
			}
			@mixin content-animation {
				height: 100%;
				position: absolute;
				width: 100%;
				cursor: pointer;
				overflow: hidden;
			}
			.backgrounde-fade-animation {
				@include content-animation;
			}
			.image-fade-animation {
				@include content-animation;
				z-index: 9;
			}
		}
		.right-container {
			padding: 60px;
			padding-top: 10px;
			.right-container-nav {
				.navigation {
					margin-top: 10px;
					padding-left: 0px;
					.panding-done {
						color: #b0b9c4;
					}
				}
			}
		}
	}
}

//slider 1

.screen1 {
	background: #f2ac4a; /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #f2ac4a, #f2b84b); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#f2ac4a,
		#f2b84b
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.screen2 {
	background: #f2ac4a; /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #000000, #f2b84b); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#000000,
		#f2b84b
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.screen3 {
	background: #f2ac4a; /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #f2ac4a, #f2b84b); /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(
		to right,
		#f2ac4a,
		#f2b84b
	); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.intro-slide-1-container {
	padding: 5px;
	padding-top: 70px;
	.text-heading {
		color: #687482;
		margin-left: 20px;
	}
	.gride-space {
		padding-top: 15px;
	}
	.search-tab {
		border-radius: 10px;
		padding: 5px;
		width: 100%;
	}
	.content-row {
		margin-left: 20px;
		margin-top: 10px;
		margin-bottom: 10px;
		.content-col {
			.card-div {
				margin-left: 5px;
				.card-div-img {
					width: 40px;
					padding-top: 0px;
					margin-top: 0px;
				}
				.card-div-body {
					padding-left: 10px;
					.font-main {
						font-size: 12px;
					}
					.font-sub {
						font-size: 11px;
						color: gray;
					}
					.card-div-body-button {
						margin-top: 2px;
						padding: 2px;
						font-size: 10px;
						background-color: #46abf6;
					}
				}
			}
		}
		.before-submit-button {
			padding-left: 15px;
			.submit-button {
				background-color: green;
				color: white;
				@media screen and (max-width: $break-small) {
					float: right;
				}
			}
		}
	}
}

//slider 2
.intro-slide-2-container {
	.sub-container {
		border: 2px solid transparent;
		border-image-slice: 1;
		border-radius: 15px;
		height: 100%;
		position: absolute;
		width: 80%;
		max-height: fit-content;
		text-align: center;
		padding-top: 10%;
		@media screen and (max-width: $break-small) {
			width: 100%;
			position: unset;
		}
		div {
			margin-bottom: 10px;
		}

		input {
			width: 70%;
			padding: 10px;
			border-radius: 10px;
			@media screen and (max-width: $break-small) {
				width: 90%;
			}
		}

		.submit-button {
			background-color: green;
			color: white;
			margin-right: 15%;
			float: right;
			@media screen and (max-width: $break-small) {
				margin-right: 5%;
			}
		}
	}
}

//image slider

.img-slider-container {
	padding: 25px !important;
	padding-top: 20px;
	color: #966c14;
	height: 100%;

	img {
		width: 40px;
		margin-bottom: 40px;
	}
	.content {
		background-color: white;
		border-radius: 10px;
		padding: 10px;
	}
}
